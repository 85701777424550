@keyframes blink {
  50% {
    border: 2px solid rgb(193, 54, 0);
  }
}
.borderBlink {
  animation: blink 1s step-end infinite alternate;
}
@keyframes rxBlinkFrames {
  50% {
    background-color: rgba(255, 215, 0, 0);
  }
}
.rxBlink {
  animation: rxBlinkFrames 0.5s step-end infinite alternate;
}
.AccLabel {
  text-align: left;
  position: absolute;
  user-select: none;
  font-size: 14px;
  line-height: 0.7;
}
.Field {
  padding-top: 0;
  padding-bottom: 2px;
  margin-top: 0;
  display: inline-block;
  border: 2px solid transparent;
}

.Field:hover {
  border: 2px solid green;
}
