.windowSdd {
  font: 12px sans-serif;
  background: #444444;
  padding: 2px;
  height: 100px;
}

.buttonSDD {
  text-decoration: none;
  user-select: none;
  color: rgb(193, 193, 193);
  font-size: 12px;
  display: block;
  height: 24px;
  line-height: 22px;
  text-align: center;
  border: 1px solid rgb(40, 40, 40);
  border-radius: 10px;
  background: linear-gradient(
    0deg,
    rgb(74, 74, 74) 0%,
    rgb(70, 70, 70) 50%,
    rgb(76, 76, 76) 51%,
    rgb(74, 74, 74) 100%
  );
  min-width: 26px;
  width: 108px;
  padding: 0 10px 0 10px;
}

.buttonSDD:hover {
  background: linear-gradient(
    0deg,
    rgb(127, 127, 127) 0%,
    rgb(133, 133, 133) 50%,
    rgb(134, 134, 134) 51%,
    rgb(129, 129, 129) 100%
  );
  border: 1px solid rgb(40, 40, 40);
}

.buttonSDDdisabled {
  user-select: none;
  color: grey;
  font-size: 12px;
  display: block;
  height: 22px;
  line-height: 23px;
  border: 1px solid black;
  border-radius: 10px;
  background: rgb(40, 40, 40);
  padding: 0 10px 0 10px;
}
.buttonSDDdisabled:hover {
  border: 1px solid black;
  border-radius: 10px;
  background: rgb(40, 40, 40);
}

.buttonSDDpressed {
  background: linear-gradient(
    0deg,
    rgb(107, 107, 107) 0%,
    rgb(113, 113, 113) 50%,
    rgb(114, 114, 114) 51%,
    rgb(109, 109, 109) 100%
  );
  border: 1px solid rgb(40, 40, 40);
}

.windowSdd ul,
.configWindow ul {
  height: 25px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.windowSdd li,
.configWindow li {
  display: inline;
  float: left;
  margin: 0;
}

.windowMsdp ul {
  position: absolute;
  top: 26px;
  left: 6px;
}

.infoButtons {
  display: block;
  text-align: center;
  width: 60px;
  color: green;
  margin: auto;
  line-height: 23px;
  user-select: none;
}

.windowMsdp {
  position: absolute;
  display: none;
}

.infoArea {
  width: 90%;
  background: #454545;
}

.headerTh {
  color: black;
  background: rgb(115, 130, 150);
  border-left: 1px solid rgb(149, 169, 165);
  border-top: 1px solid rgb(149, 169, 165);
  border-right: 1px solid rgb(80, 91, 105);
  border-bottom: 1px solid rgb(80, 91, 105);
  padding: 0 3px 0 3px;
  text-align: left;
}

.sddTableTd {
  padding: 0 2px 0 2px;
}

.sddTableTr td {
  border-bottom: 1px dashed rgb(70, 70, 70);
  border-top: 1px dashed rgb(70, 70, 70);
}

.sddTableTr td:first-child {
  border-left: 1px dashed rgb(70, 70, 70);
}

.sddTableTr td:last-child {
  border-right: 1px dashed rgb(70, 70, 70);
}

.sddTableTr:hover {
  background-color: rgb(75, 85, 100);
}

.sddTableTr:hover td {
  border-bottom: 1px dashed black;
  border-top: 1px dashed black;
}

.sddTableTr:hover td:first-child {
  border-left: 1px dashed black;
}

.sddTableTr:hover td:last-child {
  border-right: 1px dashed black;
}

.configWindow {
  color: white;
  font: 12px Arial, sans-serif;
}

.close-button {
  color: white;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 14px;
  border-radius: 2px;
  background: #882025;
}

.close-button:active:hover div.close-button-cross {
  top: -3px;
}

.close-button-cross {
  transform: rotate(45deg);
  user-select: none;
  position: absolute;
  left: 6px;
  top: -5px;
  font-weight: bold;
}

.window-header {
  background: linear-gradient(
    0deg,
    rgb(94, 94, 94) 0%,
    rgb(68, 68, 68) 81%,
    rgb(134, 134, 134) 100%
  );
  width: 100%;
  height: 22px;
  border-radius: 7px 7px 0 0;
  border-top: 1px solid black;
  line-height: 0;
  /*border-left: 1px solid black;*/
  /*border-right: 1px solid black;*/
  text-align: center;
  padding: 4px 0;
}

.window-header-text {
  color: white;
  font: 12px Arial, sans-serif;
}

.window-content {
  border-bottom: 1px solid black;
  /*border-left: 1px solid black;*/
  /*border-right: 1px solid black;*/
  background: rgb(70, 70, 70);
  width: 100%;
  height: calc(100% - 0px);
}

.cwp-window {
  width: auto;
  height: auto;
  position: absolute;
  user-select: none;
  background: rgb(70, 70, 70);
  border-radius: 7px 7px 0 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.4);
  z-index: 99;
}

fieldset {
  border: 1px solid black;
  border-radius: 5px;
}

legend {
  color: white;
}

fieldset input + span {
  display: inline-block;
  width: 50px;
}

.TrackLayer {
  user-select: none;
  position: absolute;
  left: 0;
  top: 0;
}

.trackPlan {
  user-select: none;
  position: absolute;
  color: #065f06;
  font-size: 11px;
  text-align: left;
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
  padding-right: 5px;
  border: 1px dashed;
  border-collapse: collapse;
  width: 70px;
}

.td:hover {
  color: navy;
  font-weight: bold;
}

/*



 */

/*
Toggle Radios v1.1
by Adam Culpepper | @adamculpepper
https://github.com/adamculpepper/toggle-radios
*/

/* Customizable styles */

/* Colors: Default (blue) */
.toggle-radio {
  background: #f5f5f5;
  border: 1px solid #ccc;
}

.toggle-radio > input:checked + label {
  background: lightblue;
}

.toggle-radio > input + label:not(:last-of-type) {
  border-right: 1px solid #ccc;
}

/* Rounded corners */
.toggle-radio {
  border-radius: 4px;
}

/* //////////////////////////
CORE STYLES BELOW - NO TOUCHY
////////////////////////// */
.toggle-radio {
  display: inline-flex;
}

.toggle-radio > input[type='radio'] {
  display: none;
}

.toggle-radio > input[disabled] + label {
  opacity: 0.5;
}

.toggle-radio > input[disabled] + label:hover {
  cursor: not-allowed;
}

.toggle-radio > input + label {
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 10px;
  float: left;
  cursor: pointer;
}

/* Transitions */
.toggle-radio > input:checked + label {
  transition: background 300ms linear;
}

/* //////////////////////////
CORE STYLES ABOVE - NO TOUCHY
////////////////////////// */

/* Style: Rounded */
.toggle-radio[data-style='rounded'] {
  border-radius: 500px;
}

/* Style: Square */
.toggle-radio[data-style='square'] {
  border-radius: 0;
}

/* Color: Red */
.toggle-radio[data-color='red'] > input:checked + label {
  background: rgba(231, 76, 60, 0.6);
}

/* Color: Orange */
.toggle-radio[data-color='orange'] > input:checked + label {
  background: rgba(230, 126, 34, 0.6);
}

/* Color: Yellow */
.toggle-radio[data-color='yellow'] > input:checked + label {
  background: rgba(241, 196, 15, 0.6);
}

/* Color: Green */
.toggle-radio[data-color='green'] > input:checked + label {
  background: rgba(46, 204, 113, 0.6);
}

/* Color: Blue */
.toggle-radio[data-color='blue'] > input:checked + label {
  background: rgba(52, 152, 219, 0.6);
}

/* Color: Purple */
.toggle-radio[data-color='purple'] > input:checked + label {
  background: rgba(155, 89, 182, 0.6);
}

/* Color: Gray */
.toggle-radio[data-color='gray'] > input:checked + label {
  background: rgba(85, 85, 85, 0.6);
}

/*          Callsign Menu        */

.callsignMenu {
  position: absolute;
  user-select: none;
  color: grey;
  background: #454545;
  z-index: 150;
}

.filterHistFlights {
  width: 100%;
  max-width: 65px;
}

.form_radio_btn {
  display: inline-block;
  /*margin-right: 10px;*/
}
.form_radio_btn input[type='radio'] {
  display: none;
}
.form_radio_btn label {
  display: inline-block;
  cursor: pointer;
  color: black;
  background: linear-gradient(
    0deg,
    rgb(255, 40, 40) 0%,
    rgb(251, 36, 36) 50%,
    rgb(255, 44, 44) 51%,
    rgb(255, 40, 40) 100%
  );
}

/* Checked */
.form_radio_btn input[type='radio']:checked + label {
  background: #04fd04;
}

.form_radio_btn label:hover {
  background: linear-gradient(
    0deg,
    rgb(255, 110, 110) 0%,
    rgb(251, 106, 106) 50%,
    rgb(255, 114, 114) 51%,
    rgb(255, 110, 110) 100%
  );
}

/* LOADER */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
